import React, {useEffect} from 'react'
import Nav from '../components/nav'
import Footer from '../components/footer'
import {gsap} from 'gsap'
import '../stylings/news.css'

// Assets
import {ReactComponent as BackgroundBase} from '../assets/background_colorful_v3.svg'
import {ReactComponent as BackgroundCutout} from '../assets/background_colorful_hand_v3.svg'
import {ReactComponent as Letters} from "../assets/news_letters.svg";
import {ReactComponent as DownArrow} from "../assets/down_arrow.svg";
import CornellArticle from "../assets/CornellCover.png";
import LCLArticle from "../assets/LCLCover.png";
import TechArticle from "../assets/TechCover.png";
import RevArticle from "../assets/RevCover.png";
import {Helmet} from "react-helmet";

const News = () => {
	useEffect(() => {
		let opening_home_animation = gsap.timeline({})
		opening_home_animation.from('.PageTitle', {
			display: "none",
			duration: 2,
			opacity: 0,
			delay: .5,
			ease: "slow(0.7, 0.7, false)"
		})
		opening_home_animation.from('.PageSubTitle', {
			display: "none",
			duration: 1,
			opacity: 0,
			ease: "slow(0.7, 0.7, false)"
		}, "-=1.5")
		opening_home_animation.from(['.NavButton', '.ArrowButton'], {
			display: "none",
			duration: 1,
			opacity: 0,
			y: () => Math.random() * 200,
			stagger: .25,
			ease: "slow(0.7, 0.7, false)"
		}, "-=.5")

		// Get size length of text
		// const logo = document.querySelectorAll("#news_letters path");
		//
		// for (let i = 0; i < logo.length; i++) {
		// 	console.log(i + ": " + logo[i].getTotalLength());
		// }
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>News</title>
				<meta name="description" content="Invictus BCI -- Recent Media Coverage"/>
			</Helmet>

			{/*Background Images*/}
			<div className={"BackgroundLayers"}>
				<BackgroundBase className={"PageBackgroundBase"}/>
				<div className={'NewsAccentLayer AccentLayer'}/>
				<BackgroundCutout className={"PageBackgroundCutout"}/>
			</div>

			{/*Title Page*/}
			<div className={'NewsGridLayer MainPageLayer MainGridLayer_2'}>
				<Nav/>
				<div className={'PageTitle'}>
					<Letters/>
				</div>
				<a className={'ArrowButton'} href="#section2">
					<DownArrow href="#section2" className={'MovingDownArrow'}/>
				</a>
				<div className={'NewsBodyPage'} id="section2">
					<div className="NewsArticles">
						<a href={'https://tech.cornell.edu/news/cornell-tech-student-uses-machine-learning-to-create-a-brain-powered-prosthetic/'} target="_blank" rel="noopener noreferrer">
							<div className="Article_1 Articles">
								<div className={"TechText ArticleTitle"}>
									<div className={"ArticleTitleText"}>
										Cornell Tech
									</div>
								</div>
								<img src={TechArticle} alt='Cornell Tech' className={'TechCover ArticleCover'}/>
							</div>
						</a>
						<a href={'https://news.cornell.edu/stories/2020/05/open-studio-projects-highlight-innovation-problem-solving'} target="_blank" rel="noopener noreferrer">
							<div className="Article_2 Articles">
							<div className={"CornellText ArticleTitle"}>
								<div className={"ArticleTitleText"}>
									Cornell Chronicle
								</div>
							</div>
							<img src={CornellArticle} alt='Cornell Tech' className={'CornellCover ArticleCover'}/>
						</div>
						</a>
						<a href={'https://www.facebook.com/lifechanginglabs/posts/the-winner-for-the-2019-summer-incubator-is-invictus-bci-unconquerable-invictus-/2692124354152091/'} target="_blank" rel="noopener noreferrer">
							<div className="Article_3 Articles">
								<div className={"LCLText ArticleTitle"}>
									<div className={"ArticleTitleText"}>
										Life Changing Labs
									</div>
								</div>
								<img src={LCLArticle} alt='Cornell Tech' className={'LCLCover ArticleCover'}/>
							</div>
						</a>
						<a href={'https://www.revithaca.com/spotlight-hardware-accelerator-team-aims-to-improve-prosthetics/'} target="_blank" rel="noopener noreferrer">
						<div className="Article_4 Articles">
							<div className={"RevText ArticleTitle"}>
								<div className={"ArticleTitleText"}>
									Rev Ithaca
								</div>
							</div>
							<img src={RevArticle} alt='Cornell Tech' className={'RevCover ArticleCover'}/>
						</div>
						</a>
					</div>
				</div>
				<div className={'PageSubTitle'}>
					Recent Media Coverage
				</div>
				<Footer/>
			</div>
		</>
	)
}

export default News
